import { GroupType } from '../../clubhouse/models/group-type';

export class PmgPocChatNotificationFlagsHelper {
    public static generateNotificationFlag = (
        groupType: GroupType,
        groupId: number,
        discussionId: number
    ) =>
        groupType === GroupType.PMG
            ? `PMG.${groupId}.CLUBBOARD.${discussionId}`
            : `POC.${discussionId}`;
}
