import { createSlice } from '@reduxjs/toolkit';
import { NotificationFlagsState } from './notification-flags.state';
import { RefreshNotificationFlags } from './refresh-notification-flags';
import { NotificationFlag } from './notification-flag';
import { RefreshNotificationFlagsExceptPrefix } from './refresh-notification-flags-except-prefix';

const initialState: NotificationFlagsState = {
    flags: [],
    flagsToDelete: [],
    deletedFlags: [],
    refreshAfterRemoval: false,
    isLoading: false,
    status: 'succeeded',
    error: '',
};

const notificationFlagsSlice = createSlice({
    name: 'notificationFlags',
    initialState,
    reducers: {
        addNotificationFlagToDelete(state, action) {
            const isAddedToDeleteList = state.flagsToDelete.some(
                (f) =>
                    f.eventCode.toLowerCase() ===
                    action.payload.eventCode.toLowerCase()
            );
            const isAddedToDeletedList = state.deletedFlags.some(
                (f) =>
                    f.eventCode.toLowerCase() ===
                    action.payload.eventCode.toLowerCase()
            );
            if (!isAddedToDeleteList && !isAddedToDeletedList) {
                state.flagsToDelete.push(action.payload);
                state.refreshAfterRemoval = action.payload.refreshAfterRemoval;
            }
        },
        deleteNotificationFlags(state, action) {
            const deletedFlags = action.payload as NotificationFlag[];
            state.flagsToDelete = state.flagsToDelete.filter((f) =>
                deletedFlags.every(
                    (d) =>
                        d.eventCode.toLowerCase() !== f.eventCode.toLowerCase()
                )
            );
            state.deletedFlags.push(...deletedFlags);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(RefreshNotificationFlags.pending, (state) =>
                setPendingState(state)
            )
            .addCase(RefreshNotificationFlags.fulfilled, (state, action) => {
                state.flags = action.payload.data;
                state.deletedFlags = [];
                setSucceededState(state);
            })
            .addCase(RefreshNotificationFlags.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(RefreshNotificationFlagsExceptPrefix.pending, (state) =>
                setPendingState(state)
            )
            .addCase(
                RefreshNotificationFlagsExceptPrefix.fulfilled,
                (state, action) => {
                    const prefixToIgnore = action.meta.arg.prefixToIgnore;
                    let flagsToKeep = state.flags.filter((f) =>
                        f.eventCode
                            .toLowerCase()
                            .startsWith(prefixToIgnore.toLowerCase())
                    );
                    let newFlags: NotificationFlag[] = action.payload.data;
                    flagsToKeep
                        .filter((f) =>
                            newFlags.every(
                                (nf: NotificationFlag) =>
                                    nf.eventCode.toLowerCase() !==
                                    f.eventCode.toLowerCase()
                            )
                        )
                        .forEach((f) => newFlags.push(f));
                    state.flags = newFlags;
                    state.deletedFlags = [];
                    setSucceededState(state);
                }
            )
            .addCase(RefreshNotificationFlagsExceptPrefix.rejected, (state) =>
                setRejectedState(state)
            );
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};

export const { addNotificationFlagToDelete, deleteNotificationFlags } =
    notificationFlagsSlice.actions;
export default notificationFlagsSlice.reducer;
