import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { useStateSelector } from '../../store/selectors';
import './NotificationFlag.scss';
import { Tooltip } from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { addNotificationFlagToDelete } from '../../slices/notification-flags/notification-flags.slice';

interface NotificationFlagProps {
    eventCode: string;
    reserveEmptySpace?: boolean;
    flagClassName?: string;
    includePrefix?: boolean;
    isInitializing?: boolean;
    doNotRemove?: boolean;
}
export const NotificationFlag = (props: NotificationFlagProps) => {
    const notificationFlags = useStateSelector(
        (s) => s.notificationFlags.flags
    );
    const dispatch = useDispatch();
    const [numberOfNotifications, setNumberNotifications] = useState(0);
    const [isAddedToDeleteList, setIsAddedToDeleteList] = useState(false);

    const countNumberOfNotifications = () => {
        const flags = props.includePrefix
            ? notificationFlags.filter((f) =>
                  f.eventCode
                      .toLowerCase()
                      .startsWith(props.eventCode.toLowerCase())
              )
            : notificationFlags.filter(
                  (f) =>
                      f.eventCode.toLowerCase() ===
                      props.eventCode.toLowerCase()
              );
        return flags.reduce((sum, flag) => sum + flag.count, 0);
    };

    const removeNotificationIfFullMatch = () => {
        const flag = notificationFlags.find(
            (f) => f.eventCode.toLowerCase() == props.eventCode.toLowerCase()
        );
        if (flag) {
            dispatch(addNotificationFlagToDelete(flag));
        }
    };

    useEffect(() => {
        if (props?.eventCode) {
            const numberOfNotifications = countNumberOfNotifications();
            setNumberNotifications(numberOfNotifications);
            if (
                !isAddedToDeleteList &&
                !props.doNotRemove &&
                numberOfNotifications > 0
            ) {
                removeNotificationIfFullMatch();
                setIsAddedToDeleteList(true);
            }
        }
    }, [props, notificationFlags]);

    return numberOfNotifications > 0 && !props.isInitializing ? (
        <>
            {props.reserveEmptySpace ? (
                <div className="notification-flag-placeholder"> </div>
            ) : (
                []
            )}
            <div
                className={
                    (props.flagClassName ? props.flagClassName : '') +
                    ' notification-flag'
                }>
                <Badge
                    className={'notification-flag-badge'}
                    id={'notification-flag-' + props.eventCode}>
                    {numberOfNotifications > 9 ? '9+' : numberOfNotifications}
                </Badge>
                {numberOfNotifications > 9 ? (
                    <Tooltip
                        anchorId={'notification-flag-' + props.eventCode}
                        className="tooltip-content"
                        id={'notification-flag-tooltip-' + props.eventCode}
                        place="top">
                        <div>{numberOfNotifications}</div>
                    </Tooltip>
                ) : (
                    []
                )}
            </div>
        </>
    ) : (
        <></>
    );
};
