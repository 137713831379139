import React, { useEffect, useState } from 'react';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import moment from 'moment';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import './PurchaseHistoryPaginatedTable.scss';
import Helpers from '../../../../utils/helper';
import createNotification from '../../../../utils/createNotification';
import {
    GiftCardTransactionTypeIds,
    TransactionSource,
} from '../../../../wallet/models/TransactionSource';
import { useStateSelector } from '../../../../store/selectors';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';
import GiftCard from '../../../../components/GiftCard/GiftCard';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export interface PurchaseItem {
    id: string;
    dateCreated: Date;
    transactionNumber: string;
    purchasedItem: number;
    cardInfo: CardInfo;
    actionUser: string;
}

export interface CardInfo {
    lastDigits: number;
    paymentSize: number;
}

export const PurchasedItemsName = ['APVAR', 'CAPVAR'];

export const Discounts = [
    {
        id: 1,
        name: 'First Time Discount'
    },
    {
        id: 2,
        name: 'Quarterly Frequency Discount'
    },
    {
        id: 3,
        name: 'Semi Annual Frequency Discount'
    },
    {
        id: 4,
        name: 'Annual Frequency Discount'
    },
]

export interface RewardsHistoryPaginatedTableProps {
    onTransactionIdClick(transactionId: string): void;
    selectedTransactionId: string | null;
    isAdminAccess?: boolean;
    walletId?: number;
    reloadTable?: boolean;
}

const PurchaseHistoryPaginatedTable = (
    props: RewardsHistoryPaginatedTableProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState<PurchaseItem[]>([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [sortingState, setSortingState] = useState(null);
    const tableTooltipTargetId = 'purchase-history';

    useEffect(() => {
        handleTableChange(undefined, {
            page: page,
            sizePerPage: sizePerPage,
            sortOrder: defaultSortDirection[0].order,
            sortField: defaultSortDirection[0].dataField,
        });
    }, []);

    useEffect(() => {
        handleTableChange(undefined, {
            page: page,
            sizePerPage: sizePerPage,
            sortOrder: defaultSortDirection[0].order,
            sortField: defaultSortDirection[0].dataField,
        });
    }, [props.reloadTable]);

    useEffect(() => {
        if (
            props.selectedTransactionId &&
            !tableData.some((s) => s.id === props.selectedTransactionId)
        ) {
            handleTableChange(
                undefined,
                { ...sortingState, sizePerPage: sizePerPage, page: page },
                props.selectedTransactionId
            );
        }
    }, [props.selectedTransactionId]);

    const defaultSortDirection: [{ dataField: any; order: any }] = [
        {
            dataField: 'date',
            order: 'desc',
        },
    ];

    const onTransactionIdClick = (transactionId: string) => {
        props.onTransactionIdClick(transactionId);
    };

    const getPurchaseHistoryGridColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'date',
            text: 'Date',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY, h:mm A'),
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Transaction Number',
            sort: true,
            formatter: (cellContent: string) => (
                <div
                    className="link"
                    onClick={() => onTransactionIdClick(cellContent)}>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'actionUser',
            text: 'Purchased By',
            formatter: (cellContent: string) => (
                <div>{cellContent ? cellContent : '-'}</div>
            ),
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Purchased Item',
            formatter: (cellContent: number, row: any) => (
                <div>
                    {TransactionSource.find((t) => t.id == cellContent).value}
                    {GiftCardTransactionTypeIds.includes(cellContent) ? (
                        <>
                            <span className="info-tooltip">
                                <FontAwesomeIcon
                                    id={'gift-card-ph-' + row.id}
                                    icon={faInfoCircle}
                                    className="tooltip-icon"
                                />
                            </span>
                            <Tooltip
                                anchorId={'gift-card-ph-' + row.id}
                                className="tooltip-content gift-card-tooltip"
                                place="top">
                                <GiftCard
                                    walletId={
                                        props.isAdminAccess
                                            ? props.walletId
                                            : undefined
                                    }
                                    giftCardTransactionId={row.id}></GiftCard>
                            </Tooltip>
                        </>
                    ) : (
                        []
                    )}
                </div>
            ),
            sort: true,
        },
        {
            dataField: 'recipient',
            text: 'Recipient',
            formatter: (cellContent: number) => (
                <div>{cellContent ? cellContent : '-'}</div>
            ),
            sort: true,
        },
        {
            dataField: 'payments.type',
            text: 'Payment Method',
            sort: true,
            formatter: (_cellContent: any, row: any) => {
                const vvCredits = row.payments.some((x: any) => x.type != 3);
                const creditCard = row.payments.find((x: any) => x.type == 3);
                const discounts = row.appliedDiscounts?.map(((ad: any) => {
                    return ad;
                }))
                return (
                    <div
                        className={
                            vvCredits && creditCard || discounts?.length > 0 ? 'total-credits' : ''
                        }>
                        {vvCredits && creditCard || discounts?.length > 0 ? (
                            <div style={{ marginLeft: 0 }}>{'Total'}</div>
                        ) : (
                            <></>
                        )}
                        {discounts?.length > 0 ? (
                            <div>
                                {discounts?.map(((d: any) => {
                                    return (<div>{Discounts.find(dis => dis.id === d.discountType)?.name}</div>)
                                }))}
                            </div>
                        ) : []}
                        {vvCredits ? <div>{'VV Credits'}</div> : <></>}
                        {creditCard ? (
                            creditCard.lastDigits ? (
                                <div>{`CC #${creditCard.lastDigits}`}</div>
                            ) : (
                                <div>CC</div>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: 'payments.amount',
            text: 'Amount',
            sort: true,
            formatter: (_cellContent: any, row: any) => {
                const vvCredits = row.payments
                    .filter((x: any) => x.type != 3)
                    .map((x: any) => {
                        x.amount *= -1;
                        return x;
                    });
                const creditCard = row.payments.filter((x: any) => x.type == 3);
                const discounts = row.appliedDiscounts?.map(((ad: any) => {
                    return ad;
                }))

                return (
                    <div
                        className={
                            vvCredits.length > 0 && creditCard.length > 0 || discounts?.length > 0
                                ? 'total-credits'
                                : ''
                        }>
                        {vvCredits.length > 0 && creditCard.length > 0 || discounts?.length > 0 ? (
                            <div style={{ marginLeft: 0 }}>
                                {Helpers.currencyFormatWithBrackets(
                                    vvCredits.reduce(
                                        (sum: any, current: any) =>
                                            sum + current.amount,
                                        0
                                    ) +
                                        creditCard.reduce(
                                            (sum: any, current: any) =>
                                                sum + current.amount,
                                            0
                                        )
                                    + discounts?.reduce((sum: any, current: any) => sum + current.discountAmount, 0)
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {discounts?.length > 0 ? (
                            <div>
                                {discounts?.map(((d: any) => {
                                    return (<div>{Helpers.currencyFormatWithBrackets(d.discountAmount)}</div>)
                                }))}
                            </div>
                        ) : []}
                        {vvCredits.length > 0 ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    vvCredits
                                        .filter((x: any) => x.type != 3)
                                        .reduce(
                                            (sum: any, current: any) =>
                                                sum + current.amount,
                                            0
                                        )
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {creditCard.length > 0 ? (
                            <div>
                                {Helpers.currencyFormatWithBrackets(
                                    creditCard[0].amount
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
    ];

    const handleTableChange = (
        _type: string,
        newState: any,
        selectedTransactionId: string = null
    ) => {
        setSortingState(newState);
        axios
            .post(
                `/api/wallet/transactions${
                    props.isAdminAccess ? '/adminAccess' : ''
                }`,
                {
                    shouldUseWalletBalance: false,
                    paginationOptions: {
                        pageSize: newState.sizePerPage,
                        pageNumber: newState.page == 0 ? 1 : newState.page,
                    },
                    sortingOptions: {
                        isAscending: newState.sortOrder === 'asc',
                        field: newState.sortField,
                    },
                    isPurchaseOnly: true,
                    selectedTransactionId: selectedTransactionId,
                },
                {
                    params: {
                        walletId: props.walletId,
                    },
                }
            )
            .then((response: any) => {
                if (response && response.data) {
                    setTableData(response.data.transactions);
                    setTotalSize(response.data.totalTransactions);
                    setPage(response.data.pageNumber);
                    setSizePerPage(response.data.pageSize);
                } else {
                    createNotification(
                        'There was some error while getting transactios. Please come back later',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'There was some error while getting transactios. Please come back later',
                    'error'
                );
            });
    };

    return (
        <div className="purchase-history-paginated-talbe">
            <BootstrapTable
                remote
                bootstrap4
                keyField="id"
                data={tableData}
                columns={getPurchaseHistoryGridColumns()}
                defaultSorted={defaultSortDirection}
                filter={filterFactory()}
                pagination={paginationFactory({
                    page,
                    sizePerPage,
                    totalSize,
                    tooltipTargetId: tableTooltipTargetId,
                })}
                onTableChange={handleTableChange}
                noDataIndication={'No Data available'}
                rowClasses={(row) =>
                    props.selectedTransactionId === row.id ? 'selected' : ''
                }
            />
        </div>
    );
};
export default PurchaseHistoryPaginatedTable;
