import React, { useEffect, useState } from 'react';
import './RewardsHistoryPaginatedTable.scss';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import moment from 'moment';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import Helpers from '../../../../utils/helper';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import { RewardHistoryItem } from '../../../../models/Referral/RewardHistory/RewardHistoryItem';
import {
    RewardTypeEnum,
    RewardTypeList,
} from '../../../../models/Referral/RewardHistory/RewardType';
import { RewardItemTypeList } from '../../../../models/Referral/RewardHistory/RewardItemType';
import { useStateSelector } from '../../../../store/selectors';
import { useDispatch } from 'react-redux';
import { adminEntryReasonOptions } from '../../../Admin/Permissions/Users/Modal/WalletModal';
import { NotificationFlag } from '../../../../components/NotificationFlag/NotificationFlag';
import { RefreshNotificationFlagsExceptPrefix } from '../../../../slices/notification-flags/refresh-notification-flags-except-prefix';

export interface RewardsHistoryPaginatedTableProps {
    onTransactionIdClick(transactionId: string): void;
    selectedTransactionId: string | null;
    isAdminAccess?: boolean;
    isCharityAdminAccess?: boolean;
    userId?: number;
    isReferralView?: boolean;
    isReferralPartnerSinceHidden?: boolean;
}

const RewardsHistoryPaginatedTable = (
    props: RewardsHistoryPaginatedTableProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const dispatch: any = useDispatch();

    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState<RewardHistoryItem[]>([]);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [sortingState, setSortingState] = useState(null);
    const infoButtonText = 'Price net of applicable discounts';
    const [totalLtm, setTotalLtm] = useState(0);
    const [totalLtd, setTotalLtd] = useState(0);
    const [referralPartnerSince, setReferralPartnerSince] = useState<Date>();
    const tableTooltipTargetId = 'rewards-history';

    useEffect(() => {
        fetchRewardsHistory({
            pagination: defaultTableFilter,
            selectedTransactionId: null,
        });
    }, []);

    useEffect(() => {
        if (
            props.selectedTransactionId &&
            !tableData.some(
                (s) => s.transactionId === props.selectedTransactionId
            )
        ) {
            handleTableChange(
                undefined,
                { ...sortingState, sizePerPage: sizePerPage, page: page },
                props.selectedTransactionId
            );
        }
    }, [props.selectedTransactionId]);

    const defaultSortDirection: { dataField: any; order: any } = {
        dataField: 'dateCreated',
        order: 'desc',
    };

    const defaultTableFilter: TableFilter = new TableFilter(
        { filters: [], logic: '' },
        page,
        sizePerPage,
        {
            dir: defaultSortDirection.order,
            field: defaultSortDirection.dataField,
        }
    );

    const fetchRewardsHistory = (postObj: {
        pagination: TableFilter;
        selectedTransactionId: string | null;
    }) => {
        axios
            .post(
                `api/rewardsHistory${props.isAdminAccess ? '/userId' : ''}`,
                postObj,
                {
                    params: {
                        userId: props.userId,
                    },
                }
            )
            .then((response: any) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while fetching Rewards History data'
                    )
                ) {
                    setTableData(response.data.records.list);
                    setPage(response.data.records.pageNumber);
                    setTotalSize(response.data.records.totalItems);
                    setSizePerPage(response.data.records.pageSize);
                    setTotalLtd(response.data.totalLtd);
                    setTotalLtm(response.data.totalLtm);
                    setReferralPartnerSince(response.data.referralPartnerSince);
                }
            })
            .catch(() => {
                setTableData([]);
            });
    };

    const onTransactionIdClick = (transactionId: string) => {
        props.onTransactionIdClick(transactionId);
    };

    const getIdColumn = () => ({ dataField: 'id', text: '', hidden: true });

    const getDateCreated = () => ({
        dataField: 'dateCreated',
        text: 'Date',
        formatter: (cellContent: Date, row: RewardHistoryItem) => (
            <div className="relative-container">
                <NotificationFlag
                    flagClassName='rp-ref-reward-nf'
                    eventCode={`RP.REFERRAL_REWARDS.${row.transactionId}`}
                />
                {moment(cellContent).format('MM/DD/YYYY, h:mm A')}
            </div>
        ),
        sort: true,
    });

    const getReferredParty = () => ({
        dataField: 'referredPartyName',
        text: 'Referred Party',
        sort: true,
        formatter: (cellContent: string, row: RewardHistoryItem) => (
            <>
                {cellContent}{' '}
                {row.rewardType === RewardTypeEnum.AdminEntry && row.comment ? (
                    <InfoTooltip
                        idText={`${row.id}-reward-history`}
                        text={row.comment}></InfoTooltip>
                ) : (
                    []
                )}
            </>
        ),
    });

    const getRewardType = () => ({
        dataField: 'rewardType',
        text: 'Reward Type',
        sort: true,
        formatter: (cellContent: RewardTypeEnum, row: RewardHistoryItem) => (
            <>
                {RewardTypeList.find((f) => f.id === cellContent)?.name ?? '-'}{' '}
                {row.rewardType === RewardTypeEnum.AdminEntry && row.comment ? (
                    <InfoTooltip
                        idText={`${row.id}-reward-history`}
                        text={row.comment}></InfoTooltip>
                ) : (
                    []
                )}
            </>
        ),
    });

    const getRewardItem = (text: string) => ({
        dataField: 'rewardItem',
        text: text,
        sort: true,
        formatter: (cellContent: RewardTypeEnum, row: RewardHistoryItem) => {
            if (row.rewardType === RewardTypeEnum.AdminEntry) {
                const adminEntryReasonOptionIndex = cellContent + 1;
                return (
                    adminEntryReasonOptions.find(
                        (x) => x.id === adminEntryReasonOptionIndex
                    )?.name ?? '-'
                );
            }
            return (
                RewardItemTypeList.find((f) => f.id === cellContent)?.name ??
                '-'
            );
        },
    });

    const getTransactionId = () => ({
        dataField: 'transactionId',
        text: 'Transaction Number',
        sort: true,
        formatter: (cellContent: string) => (
            <div
                className="link"
                onClick={() => onTransactionIdClick(cellContent)}>
                {cellContent}
            </div>
        ),
    });

    const getGrossPrice = () => ({
        dataField: 'grossPrice',
        text: 'Gross Price',
        sort: true,
        formatter: (cellContent: number, row: RewardHistoryItem) =>
            cellContent > 0 && row.rewardType !== RewardTypeEnum.AdminEntry
                ? Helpers.currencyFormatWithBrackets(cellContent)
                : '-',
    });

    const getNetPrice = () => ({
        dataField: 'netPrice',
        text: 'Net Price',
        headerFormatter: () => {
            return (
                <div>
                    Net Price <InfoTooltip text={infoButtonText}></InfoTooltip>
                </div>
            );
        },
        sort: true,
        formatter: (cellContent: number, row: RewardHistoryItem) =>
            cellContent > 0 && row.rewardType !== RewardTypeEnum.AdminEntry
                ? Helpers.currencyFormatWithBrackets(cellContent)
                : '-',
    });

    const getRewardCreditPercent = (text: string) => ({
        dataField: 'rewardCreditPercent',
        text: text,
        sort: true,
        formatter: (cellContent: number, row: RewardHistoryItem) =>
            cellContent > 0 && row.rewardType !== RewardTypeEnum.AdminEntry
                ? cellContent + '%'
                : '-',
    });

    const getRewardCreditColumn = (text: string) => ({
        dataField: 'rewardCredit',
        text: text,
        sort: true,
        formatter: (cellContent: number, row: RewardHistoryItem) =>
            cellContent > 0
                ? Helpers.currencyFormatWithBrackets(cellContent)
                : '-',
    });

    const getCodeUsedColumn = () => ({
        dataField: 'discountCode',
        text: 'Code Used',
        sort: true,
        formatter: (cellContent: string) => cellContent ?? '-',
    });

    const getSubcodeHolderColumn = () => ({
        dataField: 'subcodeHolderName',
        text: 'Sub-Code Holder',
        sort: true,
        hidden: !!props.isCharityAdminAccess,
        formatter: (cellContent: string) => cellContent ?? '-',
    });

    const getColumns = () =>
        props.isReferralView
            ? getReferralRewardColumns()
            : getRewardTableColumns();

    const getRewardTableColumns = () => [
        getIdColumn(),
        getDateCreated(),
        getRewardType(),
        getRewardItem('Item'),
        getTransactionId(),
        getGrossPrice(),
        getNetPrice(),
        getRewardCreditPercent('Reward Credit %'),
        getRewardCreditColumn('Reward Credit'),
    ];

    const getReferralRewardColumns = () => [
        getIdColumn(),
        getDateCreated(),
        getReferredParty(),
        getSubcodeHolderColumn(),
        getCodeUsedColumn(),
        getRewardItem('Service Purchased'),
        getTransactionId(),
        getGrossPrice(),
        getNetPrice(),
        getRewardCreditPercent('Referral Credit %'),
        getRewardCreditColumn('Referral Credit'),
    ];

    const handleTableChange = (
        _type: any,
        newState: any,
        selectedTransactionId: string = null
    ) => {
        setSortingState(newState);
        let sortField = newState.sortField;
        let sortOrder = newState.sortOrder;
        let page = newState.page;
        let sizePerPage = newState.sizePerPage;

        let fetchObj = {
            pagination: new TableFilter(
                { filters: [], logic: '' },
                page,
                sizePerPage,
                { dir: sortOrder, field: sortField }
            ),
            selectedTransactionId: selectedTransactionId,
        };

        dispatch(
            RefreshNotificationFlagsExceptPrefix({
                prefixToIgnore: 'RP.REFERRED_PARTIES',
            })
        );
        fetchRewardsHistory(fetchObj);
    };

    return (
        <div>
            <div className="reward-history-paginated-talbe">
                {props.isReferralView ? (
                    <div>
                        {props.isReferralPartnerSinceHidden ? (
                            []
                        ) : (
                            <p>
                                Referral Partner Since:{' '}
                                {moment(referralPartnerSince).format(
                                    'MM/DD/YYYY'
                                )}
                            </p>
                        )}
                        <h6>
                            Total Earned, LTD:{' '}
                            <InfoTooltip
                                idText="ltd"
                                text="LTD = Life to Date"></InfoTooltip>
                            <span className="formatted-currency">
                                {Helpers.currencyFormatWithBrackets(totalLtd)}
                            </span>
                        </h6>

                        <h6>
                            Total Earned, LTM:
                            <InfoTooltip
                                idText="ltm"
                                text="LTM = Last Twelve Months"></InfoTooltip>
                            <span className="formatted-currency">
                                {Helpers.currencyFormatWithBrackets(totalLtm)}
                            </span>
                        </h6>

                        <h6 className="table-header">Transaction History</h6>
                    </div>
                ) : (
                    []
                )}
                <BootstrapTable
                    remote
                    bootstrap4
                    keyField="id"
                    data={tableData}
                    columns={getColumns()}
                    defaultSorted={[defaultSortDirection]}
                    filter={filterFactory({
                        page,
                        sizePerPage,
                        totalSize,
                    })}
                    pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        tooltipTargetId: tableTooltipTargetId,
                    })}
                    onTableChange={handleTableChange}
                    noDataIndication={'No Data available'}
                    rowClasses={(row) =>
                        props.selectedTransactionId === row.transactionId
                            ? 'selected'
                            : ''
                    }
                />
            </div>
        </div>
    );
};
export default RewardsHistoryPaginatedTable;
